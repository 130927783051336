import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { Box, Stack, Tooltip } from '@mui/material';
import { checkoutSchedule } from 'api/schedules';
import { addMinutesToTime } from 'app/helpers/formatDate';
import { encodeBase64 } from 'app/helpers/toBase64';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { AttendanceModel } from 'app/models/SchedulesModel';
import { ReactComponent as EmergencyIcon } from 'assets/icons/exclamation-triangle.svg';
import { ReactComponent as ForemanIcon } from 'assets/icons/foreman.svg';
import { ReactComponent as SystemEmergencyIcon } from 'assets/icons/system-emergency.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/primary/clock.svg';
import { ReactComponent as PlaceholderCalendarIcon } from 'assets/icons/table/calendar.svg';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PreviewImageModal from 'shared/Modals/PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from 'shared/Modals/PreviewImageModal/types';
import { ReportOnAccidentModalRef } from 'shared/Modals/ReportOnAccidentModal/types';
import UIActionButton from 'shared/ui/UIActionButton/UIActionButton';
import UITable from 'shared/ui/UITable/UITable';
import { getSchedule } from 'store/slices/schedules';
import './Attendance.sass';

interface iProps {
  disableLogic?: boolean;
}
const Attendance: React.FC<iProps> = ({ disableLogic }) => {
  const dispatch = useAppDispatch();
  const { currentSchedule } = useAppSelector((state) => state.schedules);
  const [sendLoading, setSendLoading] = useState(false);
  const [attendances, setAttendance] = useState<AttendanceModel[]>([]);

  const previewImageModalRef = useRef<PreviewImageModalRef>(null);
  const reportOnAccidentModalRef = useRef<ReportOnAccidentModalRef>(null);
  const isInProgress = currentSchedule?.stageId === 'inProgress';

  useEffect(() => {
    if (!currentSchedule) return;

    setAttendance(currentSchedule.attendance);
  }, [currentSchedule]);

  const handleCheckoutSchedule = async (unique: number) => {
    if (!currentSchedule) return alert('Schedule not selected');
    try {
      setSendLoading(true);
      await checkoutSchedule(unique);
      dispatch(getSchedule({ id: currentSchedule.unique, callback: () => setSendLoading(false) }));
    } catch {
      setSendLoading(false);
    }
  };

  return (
    <>
      <UITable
        className='attendance__table'
        classes={{
          bodyRow: 'attendance__table__row-active',
        }}
        data={attendances}
        headers={[
          { label: 'Full Name' },
          { label: 'Specialities' },
          { label: 'Time in' },
          { label: 'Lunch In' },
          { label: 'Lunch Out' },
          { label: 'Time out' },
          { label: 'Hours Worked' },
        ]}
        columns={[
          //* FULL NAME
          {
            renderCol: (i) => {
              return (
                <Stack direction='row' alignItems='center' spacing={0.8}>
                  {!disableLogic && (
                    <Tooltip arrow placement='top' title={i.accidentReport.length ? 'Click to expand' : ''}>
                      <ErrorOutlinedIcon
                        onClick={(e) => {
                          if (!i.accidentReport.length) return;
                          e.stopPropagation();
                          if (i.accidentReport.length) {
                            reportOnAccidentModalRef.current?.show(i.accidentReport[0]);
                          }
                        }}
                        sx={{
                          visibility: !!i.accidentReport.length ? 'visible' : 'hidden',
                          color: 'red',
                          fontSize: '1.7rem',
                          display: 'inline',
                          cursor: !!i.accidentReport.length ? 'pointer' : 'default',
                        }}
                      />
                    </Tooltip>
                  )}
                  {/* )} */}
                  <Link className='attendance__table-link' to={`/employees/${i.unique}`}>
                    <Box>{i.nameEmployee}</Box>
                  </Link>
                </Stack>
              );
            },
          },
          //* SPECIALITIES
          {
            renderCol: (i: any, idx: any) => {
              return (
                <div className='attendance__table-col' style={{justifyContent: 'left'}} >
                  <span>{i.specialities}</span>

                  {(i.isForeman) && (
                    <Tooltip
                      PopperProps={{
                        sx: {
                          '.MuiTooltip-tooltip': {
                            bgcolor: '#0FB7B8',
                          },
                          '.MuiTooltip-arrow': {
                            color: '#0FB7B8',
                          },
                        },
                      }}
                      arrow
                      placement='top'
                      title={'Foreman'}
                    >
                      <span className='attendance__table__icon-primary' style={{ 
                        marginLeft: '10px',
                        width: '75px',
                        // color: '#0FB7B8',
                        fontSize: '12px',
                        borderRadius: '5px',
                        border: '1px solid #0FB7B8',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '0px',
                        fontWeight: '700',
                        background: '#0FB7B8',
                        color: 'white',
                       }}>
                        {/* {<ForemanIcon fill='#0FB7B8' />} */}
                        Foreman
                      </span>
                    </Tooltip>
                  )}
                </div>
              );
            },
          },
          //* TIME IN
          {
            renderCol: (i, idx) => {
              return (
                <div className='attendance__table__flex'>
                  <div>{i.timeIn}</div>
                  <div
                    className='attendance__table-img'
                    onClick={() =>
                      previewImageModalRef.current?.show({
                        file: {
                          unique: i.checkInImage.unique,
                          name: `${i.unique}-check-in-image`,
                          extension: i.checkInImage.extension,
                        },
                      })
                    }
                  >
                    {!!i.checkInImage.base64.length && (
                      <img src={encodeBase64(i.checkInImage.base64, i.checkInImage.extension)} alt='preview' />
                    )}
                  </div>
                </div>
              );
            },
          },
          // * LUNCH IN
          { columnName: 'lunchOut' },

          // * LUNCH OUT
          {
            renderCol: (i, idx) => {
              return <div className='attendance__table-time'>{addMinutesToTime(i.lunchOut, i.lunchTime)}</div>;
            },
          },
          //* TIME OUT
          {
            renderCol: (i, idx) => {
              return i.timeOut ? (
                <div className='attendance__table__flex'>
                  <div>{i.timeOut}</div>
                  <div
                    className='attendance__table-img'
                    onClick={() =>
                      previewImageModalRef.current?.show({
                        file: {
                          unique: i.checkOutImage.unique,
                          name: `${i.unique}-check-out-image`,
                          extension: i.checkInImage.extension,
                        },
                      })
                    }
                  >
                    {!!i.checkOutImage.base64.length && (
                      <img src={encodeBase64(i.checkOutImage.base64, i.checkOutImage.extension)} alt='preview' />
                    )}
                  </div>
                </div>
              ) : (
                <div className='attendance__table__flex'>N/A</div>
              );
            },
          },
          //* HOURS WORKED
          isInProgress && !disableLogic
            ? {
                renderCol: (i: any, idx: any) => {
                  return (
                    <div className='attendance__table-col'>
                      <span>{i.hoursWorked || 0} h</span>
                      <div>
                        <UIActionButton
                          classes={{
                            root: 'attendance__table__icon-exit',
                          }}
                          type='exit-work'
                          onClick={() => handleCheckoutSchedule(currentSchedule?.unique!)}
                        />
                      </div>
                    </div>
                  );
                },
              }
            : {
                // columnName: 'hoursWorked'
                renderCol: (i: any, idx: any) => {
                  return (
                    <div className='attendance__table-col'>
                      <span>{i.hoursWorked || 0} h</span>

                      {(i.checkedOutByManager || i.checkedOutAutomatically) && (
                        <Tooltip
                          PopperProps={{
                            sx: {
                              '.MuiTooltip-tooltip': {
                                bgcolor: '#ffae1a',
                              },
                              '.MuiTooltip-arrow': {
                                color: '#ffae1a',
                              },
                            },
                          }}
                          arrow
                          placement='top'
                          color='red'
                          title={i.checkedOutByManagers ? 'Checked out by Manager' : 'Checked out automatically'}
                        >
                          <span className='attendance__table__icon-warning'>
                            {i.checkedOutByManagers ? <EmergencyIcon /> : <SystemEmergencyIcon fill='#ffae1a' />}
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  );
                },
              },
        ]}
        placeholder={{
          title: 'Sorry, it is empty here!',
          subtitle: 'The list of attendace is empty. It will be displayed here.',
          icon: <PlaceholderCalendarIcon />,
        }}
        footer={
          <div className='attendance__table--flex'>
            <ClockIcon />
            <span>Total Hours: {currentSchedule?.totalHrs || 0} h</span>
          </div>
        }
      />
      <PreviewImageModal ref={previewImageModalRef} />
    </>
  );
};

export default Attendance;
