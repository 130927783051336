import { CircularProgress, Stack } from '@mui/material';
import { encodeBase64 } from 'app/helpers/toBase64';
import { ForemanReportModel } from 'app/models/ForemanReportModel';
import { ReactComponent as TickCircleIcon } from 'assets/icons/primary/tick-circle.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/table/calendar.svg';
import { FC, useRef } from 'react';
import { Link } from 'react-router-dom';
import PreviewImageModal from 'shared/Modals/PreviewImageModal/PreviewImageModal';
import { PreviewImageModalRef } from 'shared/Modals/PreviewImageModal/types';
import ReportsPlaceholder from 'shared/Modals/ForemanReportsModal/components/Placeholder/Placeholder';
import './DailyReport.sass';
import SharedPreviewImageModal from 'pages/SharedSchedulesPage/components/SharedPreviewImageModal/SharedPreviewImageModal';
import AccidentReport from '../AccidentReport/AccidentReport';
import { formatDate } from 'app/helpers/formatDate';

type foremanReportType = {
  title: string;
  type: string;
  data: ForemanReportModel | null;
};
interface Props {
  report: foremanReportType;
  loading: boolean;
}

const ForemanDailyReport = ({ report, loading }: Props) => {
  const previewImageModalRef = useRef<PreviewImageModalRef>(null);
  const { data } = report;
  if (loading) {
    return (
      <div className='daily-report__loading daily-report__wrapper'>
        <CircularProgress />
      </div>
    );
  }
  if (!data) {
    return (
      <div className='daily-report__empty daily-report__wrapper'>
        <Stack alignItems={'baseline'} direction='row' gap='18px'>
          <p className='daily-report__reported-title'>{report.title}</p>
        </Stack>
        <ReportsPlaceholder
          className='daily-report__empty--placeholder'
          icon={<CalendarIcon />}
          title='Sorry, it is empty here!'
          subtitle={`The ${report.title} is empty. It will be displayed here.`}
        />
      </div>
    );
  }
  if (data.typeId === 'accidentReport') {
    return (
      <>
        {data.accidentReportData.map((accident, idx) => (
          <div key={idx} className='daily-report__wrapper'>
            <Stack alignItems={'baseline'} direction='row' gap='18px' justifyContent={'space-between'}>
              <div className='daily-report__reported-wrapper'>
                <p className='daily-report__reported-title'>
                  {report.title} - {idx + 1}
                </p>
                <span className='daily-report__reported-authorby'>by</span>
                <p className='daily-report__reported-author'>{data.nameAuthor}</p>
              </div>
              <p className='daily-report__reported-name'>
                {formatDate({
                  date: data?.createdAt,
                })}
              </p>
            </Stack>

            <AccidentReport accident={accident} />
          </div>
        ))}
      </>
    );
  }
  return (
    <>
      <div className='daily-report__wrapper'>
        <div className='daily-report__cards'>
          <Stack alignItems={'baseline'} direction='row' gap='18px' justifyContent={'space-between'}>
            <div className='daily-report__reported-wrapper'>
              <p className='daily-report__reported-title'>{report.title}</p>
              <span className='daily-report__reported-authorby'>by</span>
              <p className='daily-report__reported-author'>{data.nameAuthor}</p>
            </div>
            <p className='daily-report__reported-name'>
              {' '}
              {formatDate({
                date: data?.createdAt,
              })}
            </p>
          </Stack>
          {data?.description && <p className='daily-report__card-description'>{data.description}</p>}
        </div>
        {!!data?.files.length && (
          <div className='daily-report__attachments'>
            <div>Testing</div>
            <div className='daily-report__attachments-wrapper'>
              {data.files?.map((image, idx) => (
                <img
                  key={idx}
                  className='daily-report__attachments-image'
                  src={encodeBase64(image.file, 'jpg')}
                  alt={`${idx}`}
                  onClick={() =>
                    previewImageModalRef.current?.show({
                      file: {
                        name: `attachment-${image.unique}-${idx}`,
                        unique: image.unique,
                        extension: image.extension || 'jpg',
                      },
                    })
                  }
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <SharedPreviewImageModal ref={previewImageModalRef} />
    </>
  );
};

export default ForemanDailyReport;
